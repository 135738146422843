<template>
  <div class="wrapper">

    <h1>Заявка на регистрацию принята</h1>

    <div class="row">
      <div class="col-lg-6">
        <p>Ваша <b>заявка №{{ regNum }} </b> на регистрацию организации принята.
          Мы сообщим о результатах проверки Вашу электронную почту.
          Обычно проверка занимает 1–2 рабочих дня.</p>

        <router-link class="btn btn-border" to="/">Вернуться на главную</router-link>
      </div>
    </div>

  </div>
</template>

<script>
export default {
name: "AddResult",
  computed: {
    regNum() {
      return this.$store.getters.regNum;
    }
  }
}
</script>

<style scoped lang="scss">
h1 {
  margin-top: 0;
  margin-bottom: 79px;
  font-size: 44px;
  line-height: 1.27;
}
p {
  font-size: 18px;
  line-height: 1.56;
  margin-bottom: 70px;
}

.btn {
  width: 100%;
  max-width: 350px;
}

@include md-max {
  .btn {
    width: auto;
  }
}


@include sm-max {
  h1 {
    font-size: 18px;
    line-height: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  p {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
</style>